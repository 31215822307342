<template>
    <vx-card title="Billing Information">
        <vs-button
          @click="handleBack"
          color="danger"
          icon-pack="feather"
          icon="icon-arrow-left"
          >Back</vs-button>
      <div class="vx-row mb-6 mt-3">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Billing ID</label>
          <vs-input class="w-full" v-model="data.uuid" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Status</label>
          <vs-input class="w-full" v-model="data.status" type="text" readonly />
        </div>
      </div>
  
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Date</label>
          <vs-input class="w-full" v-model="data.date" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Due Date</label>
          <vs-input class="w-full" v-model="data.due_date" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Order</label>
          <vs-input class="w-full" v-model="data.order" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Discount</label>
          <vs-input class="w-full" v-model="data.discount" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Tax</label>
          <vs-input class="w-full" v-model="data.tax" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Charge</label>
          <vs-input class="w-full" v-model="data.charge" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Total</label>
          <vs-input class="w-full" v-model="data.total" type="text" readonly />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Outstanding</label>
          <vs-input class="w-full" v-model="data.outstanding" type="text" readonly />
        </div>
      </div>

    </vx-card>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    components: {},
    data() {
      return {
        // main data
        uuid: null,
        territory: null,
        collector_sales: null,
        id: null,
        data: null
      };
    },
    mounted(){
        this.getData();
        this.cek_bill_id();
    },
    methods: {
        cek_bill_id(){
            console.log(
                this.$route.params.id_bill
            )
        },
        handleBack() {
            this.$router.push({ name: "monitoring-dashboard-sfa" });            
        },
        async getData(){
            const id = this.$route.params.id_bill;
            // this.$vs.loading();
            // let response = await this.$http.get(`/api/sfa/v1/monitoring-dashboard/customer-visit-dashboard/show-billing`, {
            //     params: {
            //         id: id,
            //     },
            // });
            // console.log(response)
            this.$vs.loading();
            this.$http
            .get("/api/sfa/v1/monitoring-dashboard/customer-visit-dashboard/show-billing/" + id).then(resp => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    console.log(resp)
                    this.data = resp.data;
                    // this.role = resp.data.display_name;
                    // var x = resp.data.module
                    // var _this = this;
                    // this.data.forEach(function(element){
                    //     x.forEach(function(elementX){
                    //         if(element.module_id == elementX.module_id){
                    //             element["selected"] = true;
                    //             _this.selected.push(elementX.module_id)
                    //         }
                    //     })
                    // })
                }else{
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        }
    },
  };
  </script>
  